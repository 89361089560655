import '@/services/datadog.browser-rum.service'

import type { AppContext, AppInitialProps, AppProps } from 'next/app'
import App from 'next/app'
import dynamic from 'next/dynamic'
import { useRouter } from 'next/router'
import type { Session } from 'next-auth'
import { appWithTranslation } from 'next-i18next'
import { DefaultSeo } from 'next-seo'

import env from '@beam-australia/react-env'
import type { AnalyticsConfig } from '@knauf-group/cst-analytics'
import { AnalyticsProvider } from '@knauf-group/cst-analytics'
import globalTheme, {
  globalThemeAlternativeFont,
} from '@knauf-group/ct-designs/themeConfigs/rebranding/theme'
import { isUnsupportedKnaufFont } from '@knauf-group/ct-designs/utils/utils'
import SessionProvider from '@knauf-group/ct-shared-nextjs/nextauth/react'
import { createGlobalThemeBasedOnDevice } from '@knauf-group/ct-shared-nextjs/utils'
import type { DeviceType } from '@knauf-group/ct-shared-nextjs/utils/deviceType'
import { inferDeviceType } from '@knauf-group/ct-shared-nextjs/utils/deviceType'
import { CssBaseline } from '@mui/material'
import { ThemeProvider } from '@mui/material/styles'
import { AppCacheProvider } from '@mui/material-nextjs/v14-pagesRouter'
import type { IToggle } from '@unleash/nextjs'
import { FlagProvider, getFrontendFlags } from '@unleash/nextjs/client'

import type KnaufFont from '@/components/KnaufFont'
import { ANALYTICS_APP_NAME, ASSET_PREFIX } from '@/constants'
// import useNonclickablePageDuringRouting from '@/utils/frontend/useNonclickablePageDuringRouting'
import { getEmotionCache } from '@/utils/plain/getEmotionCache'
import { getPageDirection } from '@/utils/plain/getPageDirection'
import { isRTLLocale } from '@/utils/plain/isRTLLocale'
import { isStaticErrorPage } from '@/utils/plain/isStaticErrorPage'

import nextI18NextConfig from '../../next-i18next.config'

const FAVICON_URL = 'https://knauf.com/favicon.ico'

type AppOwnProps = {
  deviceType: DeviceType
  toggles: IToggle[]
}

type AppPropsWithSession = AppProps<{
  key: string
  session: Session
  now: number
}>

const analyticsConfig: AnalyticsConfig = {
  appName: ANALYTICS_APP_NAME,
  adobeToken: env('ADOBE_LAUNCH_SCRIPT_URL'),
}

const DynamicFont = dynamic<React.ComponentProps<typeof KnaufFont>>(
  () => import('@/components/KnaufFont'),
)

const MyApp = (props: AppPropsWithSession & AppOwnProps) => {
  const { Component, deviceType, toggles, pageProps } = props
  const { key, ...restPageProps } = pageProps

  const router = useRouter()
  const isRTL = isRTLLocale(router.locale)
  const direction = getPageDirection(isRTL)

  const isAlternativeFont = isUnsupportedKnaufFont(router.locale)
  const theme = isAlternativeFont ? globalThemeAlternativeFont : globalTheme

  const content = (
    <AppCacheProvider {...props} emotionCache={getEmotionCache(isRTL)}>
      <ThemeProvider theme={createGlobalThemeBasedOnDevice(deviceType, theme, direction)}>
        {!isAlternativeFont && <DynamicFont />}
        <CssBaseline />
        <DefaultSeo
          additionalLinkTags={[
            {
              rel: 'shortcut icon',
              href: FAVICON_URL,
            },
          ]}
        />
        <SessionProvider
          session={pageProps.session}
          showLoadingSessionSpinner={false}
          basePath={`/${ASSET_PREFIX}/api/auth`}
        >
          <AnalyticsProvider config={analyticsConfig}>
            <Component key={pageProps.key} {...restPageProps} />
          </AnalyticsProvider>
        </SessionProvider>
      </ThemeProvider>
    </AppCacheProvider>
  )

  // useNonclickablePageDuringRouting()

  // error pages are statically generated during the build in the pipeline, where we don't have access to env vars
  // so, Unleash cannot be initialized
  if (isStaticErrorPage(router.pathname)) {
    return content
  }

  return (
    <FlagProvider
      config={{
        url: env('UNLEASH_FRONTEND_API_URL'),
        clientKey: env('UNLEASH_FRONTEND_API_TOKEN'),
        appName: env('UNLEASH_APP_NAME'),
        bootstrap: toggles,
        disableRefresh: true,
        disableMetrics: true,
      }}
    >
      {content}
    </FlagProvider>
  )
}

// https://stackoverflow.com/a/73372426/21620391 simulate mui useQuery for SSR, that will avoid flickering
MyApp.getInitialProps = async (context: AppContext): Promise<AppOwnProps & AppInitialProps> => {
  let deviceType: DeviceType = 'desktop' // fallback to desktop

  if (context.ctx.req) {
    const userAgent = context.ctx.req.headers['user-agent']
    deviceType = inferDeviceType(userAgent ?? '')
  }

  const { toggles } = isStaticErrorPage(context.router.pathname)
    ? { toggles: [] }
    : await getFrontendFlags()

  return {
    ...(await App.getInitialProps(context)),
    deviceType,
    toggles,
  }
}

export default appWithTranslation(MyApp, nextI18NextConfig)
