import env from '@beam-australia/react-env'

export const HEADER_HEIGHT_FILES_TABLE = 40

export const ESTIMATED_ROW_HEIGHT = 55

export const HEADER_HEIGHT_DETAILS_TABLE = 56

export const MAX_ROWS_TO_SHOW = 8

export const ESTIMATED_ROW_HEIGHT_DETAILS_TABLE = 56

export const ESTIMATED_TABLE_HEIGHT =
  HEADER_HEIGHT_DETAILS_TABLE + ESTIMATED_ROW_HEIGHT * MAX_ROWS_TO_SHOW

export const HEADER_HEIGHT = 64
export const PRODUCT_BANNER_HEIGHT = 56
export const NAVIGATION_BANNER_HEIGHT = 48
export const DIY_BANNER_HEIGHT = 24

export const SIDE_PADDING_DESKTOP = 5 // 5 * 8 = 40px
export const SIDE_PADDING_MOBILE = 2 // 2 * 8 = 16px

export const ASSET_PREFIX = 'pcat-fe'
export const IMAGE_PREFIX = 'images'

export const NEXT_DATA_PATH = '/_next/data'

export const ANALYTICS_APP_NAME = 'knauf_product_catalog'

export const MUI_PRO_LICENSE_KEY =
  'eefd29e6da9ad475193fce40b1b0abdaTz03OTY0NCxFPTE3MzI4ODgxNTYwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI='

export const SECTIONS_MAP = {
  description: {
    id: 'description',
    atLeastOneAttributes: [
      'description',
      'properties',
      'applicationNotes',
      'fieldOfApplication',
      'buildingTypes',
    ],
    tKey: 'description-details-title',
  },
  documents: {
    id: 'documents',
    atLeastOneAttributes: ['documents', 'dopKey', 'sdsLinkout'],
    tKey: 'documents-section-title',
  },
  certificates: {
    id: 'certificates',
    atLeastOneAttributes: ['certificateFiles'],
    tKey: 'certificates-section-title',
  },
  'technical-data': {
    id: 'technical-data',
    atLeastOneAttributes: ['technicalData.data'],
    tKey: 'technical-data-section-title',
  },
  variants: {
    id: 'variants',
    atLeastOneAttributes: ['variants.data'],
    tKey: 'variants-section-title',
  },
  'similar-products': {
    id: 'similar-products',
    atLeastOneAttributes: ['relatedProducts'],
    tKey: 'similar-products-title',
  },
  accessories: {
    id: 'accessories',
    atLeastOneAttributes: ['accessories'],
    tKey: 'accessories-title',
  },
} as const

export type Section = keyof typeof SECTIONS_MAP

export const AVAILABILITY_UNIQUE_KEY = 'availability-unique-key'

export const HEADER_UNIQUE_KEY = 'header-unique-key'

export const DOWNLOAD_HREF = '#documents'

export const CERTIFICATE_HREF = '#certificates'
export const DOWNLOAD_MULTIPLE_FILES_PREFIX = 'Archive_'

export const VARIANT_HREF = '#variants'

export const DOWNLOAD_CENTER_SERVICE_URL = env('DOWNLOAD_CENTER_SERVICE_URL')
export const DOP_URL = env('DOP_URL')

export const LANGUAGE_SELECTOR_HREF = '/en/language-selector'

export const SECTIONS_TO_HIDE = env('SECTIONS_TO_HIDE') ?? ''

export const CROWDIN_CACHE_CLEAR_INTERVAL_IN_SECONDS = parseInt(
  process.env.TRANSLATIONS_CACHE_CLEAR_INTERVAL || '3600',
  10,
)
